/** @jsx jsx **/
import { Container, jsx, Text, Heading, Box } from 'theme-ui'
import { FC } from 'react'
import Layout from '~/components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import HeroImage from '~/components/hero-image'
import BaseTypo from '~/components/base-typo'
import { GatsbyImageType } from '~/@types/models'
import MarkdownIt from '~/components/MarkdownIt'
import Table from '~/components/table'
import theme from '~/gatsby-plugin-theme-ui'
import Dash from '~/components/dash'

type Props = {}

type QueryResult = {
  pageData: {
    frontmatter: {
      header: {
        title: string
        description: string
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }
      contactTitle: string
      contact: Array<{
        method: string
        value: string
      }>
    }
    html: string
  }
}

const TryInHomePage: FC<Props> = () => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "tryInHome" } }) {
        frontmatter {
          headerVariant
          header {
            title
            description
            image {
              ...heroImage
            }
            imageMobile {
              ...mobileHeroImage
            }
          }
          contactTitle
          contact {
            method
            value
          }
        }
        html
      }
    }
  `)
  const {
    frontmatter: { header, contactTitle, contact },
    html: content,
  } = query.pageData

  const contactNew = contact.map(item => ({
    firstCol: item.method,
    secondCol: item.value,
  }))

  return (
    <Layout>
      <HeroImage
        height="primary"
        images={{
          default: header.image,
          mobile: header.imageMobile,
        }}
      >
        <Container
          sx={{
            textAlign: [null, 'center'],
          }}
        >
          <Heading
            as="h2"
            variant="title2"
            sx={{
              color: 'inherit',
              variant: 'text.h0',
              mb: 27,
            }}
          >
            {header.title}
          </Heading>
          <Container
            sx={{
              maxWidth: 576,
            }}
          >
            <Text
              variant="regular"
              sx={{
                color: 'inherit',
                display: ['none', 'block'],
              }}
            >
              {header.description}
            </Text>
          </Container>
        </Container>
      </HeroImage>
      <Container
        variant="containerPrimary"
        sx={{
          my: [theme.space.l, null, theme.space.xl2],
        }}
      >
        <Text
          sx={{
            display: ['block', null, 'none'],
            mb: [theme.space.md1],
          }}
          variant="regular"
        >
          {header.description}
        </Text>
        <Box mb={[theme.space.l, null, theme.space.xl]}>
          <BaseTypo>
            <MarkdownIt content={content} />
          </BaseTypo>
        </Box>
        <Heading as="h4" variant="title5">
          {contactTitle}
        </Heading>
        <Dash sxProps={{ my: theme.space.md1 }} />
        <Table data={contactNew} />
      </Container>
    </Layout>
  )
}

export default TryInHomePage
