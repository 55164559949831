import { useEffect, useState } from 'react'

const useTopBanner = () => {
  const [height, setHeight] = useState(0)

  const handleHeight = () => {
    const topBanner = document.getElementById('top-banner')
    const heightTopBanner = topBanner ? topBanner.clientHeight : 0
    setHeight(heightTopBanner)
  }

  useEffect(() => {
    handleHeight()

    window.addEventListener('resize', handleHeight)

    return () => window.removeEventListener('resize', handleHeight)
  }, [])

  return {
    height,
  }
}

export default useTopBanner
