/** @jsx jsx */
// @ts-ignore
import Img from 'gatsby-image'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import { FC, useMemo, Fragment } from 'react'
import { Container, jsx, Box, Image, SxStyleProp } from 'theme-ui'
import { HeroHeight, HeroImageSet, Maybe, ImageTypes } from '~/@types/models'
import useImageSources from '~/components/hero-image/use-image-sources'
import ImageV2 from '~/componentsV2/image'
import useTopBanner from '~/hooks/use-top-banner'

interface Props extends Partial<ImageTypes> {
  variant?: 'default' | 'primary' | 'tertiary'
  height?: 'default' | 'primary' | 'auto' | 'aspectRatio'
  heightV2?: {
    mobile?: keyof typeof HeroHeight
    desktop?: keyof typeof HeroHeight
  }
  as?: React.ElementType
  images?: Maybe<HeroImageSet>
  isExternal?: boolean
  textAtBottom?: boolean
  heroSx?: SxStyleProp
  children?: React.ReactNode
  imageSrc?: string
  imageUrl?: string
}

const HeroImage = ({
  children,
  variant = 'default',
  height = 'default',
  as = 'header',
  images,
  isExternal = false,
  heightV2,
  textAtBottom = false,
  heroSx,
  imageV2,
  imageSrc,
  imageUrl,
}: Props) => {
  const { height: topBannerHeight } = useTopBanner()

  const heroHeight = useMemo(() => {
    switch (height) {
      case 'primary':
      case 'auto':
        return `calc(100vh - ${topBannerHeight}px)`
      case 'aspectRatio':
        return `auto`
      default:
        return 885
    }
  }, [height])

  const mobileHeroHeight = useMemo(() => {
    const defaultHeight = 'auto'

    if (!heightV2) {
      return defaultHeight
    }

    switch (heightV2.mobile) {
      case 'primary':
        return `calc(100vh - ${topBannerHeight}px)`
      case 'auto':
      default:
        return defaultHeight
    }
  }, [heightV2])

  const imageSources = useImageSources({
    images,
    isExternal,
  })

  const imageNew = imageV2 ? getImage(imageV2) : null
  return (
    <Fragment>
      <Box
        id="hero-image"
        as={as}
        sx={{
          position: 'relative',
          width: '100%',
          height: [mobileHeroHeight, heroHeight],
          '@media(max-height: 1000px)': {
            height: mobileHeroHeight,
          },
          color: 'main',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            height: '100%',
            '> div': {
              height: '100%',
            },
            img: {
              width: '100%',
              height: '100%',
            },
          }}
        >
          {imageUrl ? <img src={imageUrl} alt="hero-image" /> : null}
          {imageSources &&
            !imageNew &&
            (isExternal ? (
              <Box
                sx={{
                  height: '100%',
                  '> div:first-child': {
                    height: '100%',
                    minHeight: [mobileHeroHeight, heroHeight],
                  },
                }}
              >
                {imageSources && imageSources.length > 0 && (
                  <picture>
                    {imageSources.map(source => {
                      return <source key={source?.src} srcSet={source?.src} media={source?.media} />
                    })}
                    <Image
                      src={imageSources[0].src}
                      sx={{
                        objectFit: `cover`,
                      }}
                      alt="hero-image"
                    />
                  </picture>
                )}
              </Box>
            ) : (
              <Img
                // @ts-ignore
                fluid={imageSources}
                fadeIn={false}
                loading="eager"
                sx={{
                  height: '100%',
                  '> div:first-child': {
                    minHeight: [mobileHeroHeight, heroHeight],
                  },
                }}
              />
            ))}
          {imageNew && <ImageV2 image={imageNew} alt="hero-image" />}
          {children && (
            <Container
              variant="layout.collections.header"
              sx={{
                position: 'absolute',
                pt: variant === 'tertiary' ? 0 : [90, null, null, 176],
                justifyContent: textAtBottom === true ? ['end', 'center'] : null,
                ...heroSx,
              }}
            >
              {children}
            </Container>
          )}
        </Box>
      </Box>
    </Fragment>
  ) as JSX.Element
}

export default HeroImage
