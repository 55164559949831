import { GatsbyImageProps } from 'gatsby-image'
import { HeroImageSet, ImageResponsive, Maybe } from '~/@types/models'

type ExternalImageSource = Array<{
  src?: string
  media?: Maybe<string>
}>

type GatsbyImageSource = Array<Partial<GatsbyImageProps>>

interface ImageSourceProps {
  images: Maybe<HeroImageSet> | Maybe<ImageResponsive>
  isExternal?: boolean
}

const mapGatsbyImages = (images: HeroImageSet): GatsbyImageSource => {
  let imageSources = []

  if (images.mobile) {
    imageSources.push(images.mobile.childImageSharp.fluid)
    imageSources.push({
      ...images.default.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    })
  } else {
    imageSources = [{ ...images.default.childImageSharp.fluid }]
  }

  // @ts-ignore
  return imageSources
}

const mapExternalImages = (image: ImageResponsive): ExternalImageSource => {
  let imageSources = []

  if (image.mobile) {
    imageSources.push({
      src: image?.default?.originalSrc,
      media: `(min-width: 768px)`,
    })
    imageSources.push({
      src: image?.mobile?.originalSrc,
    })
  } else {
    imageSources = [
      {
        src: image?.default?.originalSrc,
      },
    ]
  }

  return imageSources
}

export const mapImageSources = ({ images, isExternal }: ImageSourceProps) => {
  let imageSources = null
  if (images) {
    imageSources = []

    if (isExternal) {
      imageSources = mapExternalImages(images as ImageResponsive)
    } else {
      imageSources = mapGatsbyImages(images as HeroImageSet)
    }
  }

  return imageSources || []
}

const useImageSources = (
  props: ImageSourceProps
): ExternalImageSource | GatsbyImageSource | null => {
  if (!props.images) {
    return null
  }

  return mapImageSources(props)
}

export default useImageSources
