import React from 'react'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import './styles.css'
import { isNumber } from 'util'

interface ImageV2Props extends GatsbyImageProps {
  aspectRatio?: number | boolean
  overlay?: boolean
  overlayColor?: string
}

const ImageV2 = ({
  image,
  aspectRatio = false,
  overlay = false,
  overlayColor = 'rgba(0,0,0,0.5)',
  style,
  ...props
}: ImageV2Props) => {
  const imageProps = aspectRatio
    ? { aspectRatio: typeof aspectRatio === 'number' ? aspectRatio : image.width / image.height }
    : {}

  return (
    <div
      className={`image-component-wrapper ${aspectRatio ? 'with-aspect-ratio' : ''}`}
      style={style}
    >
      <GatsbyImage
        image={image}
        loading="eager"
        style={{
          width: '100%',
          height: '100%',
        }}
        {...props}
        {...imageProps}
      />

      {overlay && (
        <div
          className="image-component-overlay"
          style={{
            backgroundColor: overlayColor,
          }}
        ></div>
      )}
    </div>
  )
}

export default ImageV2
